import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import { useDispatch, } from "react-redux";
import { Button, Card, CardBody, CardHeader, Container, Row } from "reactstrap";
import style from "./UploadBackupFile.module.scss";
import { notify } from "../../../../utils/helpers";
import { importBackupFile } from "../../../../store/slices/idea/ideaActions";

const UploadBackupFile = () => {
    const { t } = useTranslation();
    const notificationAlertRef = useRef(null);
    const dispatch = useDispatch();

    const [file, setFile] = useState(null);

    const alertFun = (type, msg, style) => {
        notify(type, msg, notificationAlertRef, null, style);
    }
    const onImportBackupBtnClick = () => {
        dispatch(importBackupFile({ file, alertFun }));
    }

    return (
        <>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <SimpleHeader name={t("idea.ideas")} parentName={t("idea.ideasManagement")} />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <h3 className="mb-0">{t("r&r.uploadYourFile")}</h3>
                            </CardHeader>
                            <CardBody className={style.cardBody}>
                                <input
                                    type="file"
                                    accept=".json"
                                    onChange={(e) => setFile(e.target.files[0])}
                                />
                                <Button
                                    color="primary"
                                    type="button"
                                    disabled={!file?.name}
                                    style={{ width: "180px", marginBottom: "10px" }}
                                    onClick={onImportBackupBtnClick}
                                >
                                    {t('idea.importBackupFile')}
                                </Button>
                            </CardBody>
                        </Card>
                    </div >
                </Row >
            </Container >
        </>
    );
};
export default UploadBackupFile;